import { customerService, todoService } from '@/api';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsQueryPlan, OsTable } from '@/components';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { PagingMixin } from '@/mixins/paging';
import { PageQueryPlanEnum, TodoTypeEnum } from '@/resource/enum';
import { TodoList, TodoListQuery } from '@/resource/model';
import { dateFormat, debounce, messageError, translation } from '@/utils';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import { QueryPlanOperationOption } from '@/components/os-query-plan/os-query-plan';

@Component({
  components: {}
})
export default class Todo extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<TodoList> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<TodoList>> = [
    {
      prop: 'code',
      label: 'todo.code',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'type',
      label: 'todo.topic',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (rowData: object): string => {
        return translation(`todoType.${TodoTypeEnum[(rowData as TodoList).type]}`);
      }
    },
    {
      prop: 'abolitionReason',
      label: 'todo.applyReason',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'customerName',
      label: 'todo.customer',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'createUserName',
      label: 'todo.approveTheInitiator',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'createTime',
      label: 'todo.approveTheTime',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (rowData: object): string => {
        return dateFormat((rowData as TodoList).createTime);
      }
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'customerName',
      label: 'billing.customer',
      option: {
        placeholder: 'billing.selectCustomer',
        filterable: true
      },
      optionData: []
    },
    {
      type: 'Input',
      field: 'createUserName',
      label: 'todo.approveTheInitiator',
      option: {
        placeholder: 'todo.inputApproveTheInitiator'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'createTime',
      label: 'todo.approveTheTime',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    }
  ];

  /**
   * 页面标识
   */
  public code: number = PageQueryPlanEnum.todo;
  /**
   * 查询方案编辑按钮
   */
  public queryPlanEndOption: Array<QueryPlanOperationOption> = [
    {
      id: 0,
      type: 'primary',
      slot: 'end',
      label: 'button.save',
      permissionCode: 'system:query:template:save',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).dialogOpened();
      }
    },
    {
      id: 1,
      type: 'text',
      slot: 'end',
      label: 'button.edit',
      permissionCode: 'system:query:template:delete',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).editDialogOpened();
      }
    }
  ];

  private queryForm: Partial<TodoListQuery> & { createTime: Array<string> } = {
    customerName: '',
    keywords: '',
    createTime: [],
    createUserName: ''
  };

  private defaultQueryForm: Partial<TodoListQuery & { createTime: Array<string> }> = {
    customerName: '',
    keywords: '',
    createTime: [],
    createUserName: ''
  };

  public activated(): void {
    this.queryClick();
  }
  public mounted(): void {
    this.queryClick();
  }

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'finance-todo');
    this.getCustomers();
  }

  public linkToDetails(todo: TodoList): void {
    const links = [
      { path: 'billing-details', type: TodoTypeEnum.billing },
      { path: 'billing-details', type: TodoTypeEnum.abolish },
      { path: 'invoice-details', type: TodoTypeEnum.changeInvice }
    ];

    const link = links.find(x => x.type === todo.type);
    if (!link) {
      return;
    }
    this.$router.push({
      path: link.path,
      query: {
        id: todo.id.toString(),
        pid: todo.pid,
        taskId: todo.taskId,
        scope: todo.scope.toString(),
        type: todo.type.toString()
      }
    });
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    // 重置查询方案
    (this.$refs.OsQueryPlan as OsQueryPlan).id = 0;
    this.clearSelection();
    this.loadData();
  }

  @debounce()
  public queryClick(): void {
    if (this.$route.query.queryPlanName) {
      this.paging.currentPage = 1;
      (this.$refs.OsQueryPlan as OsQueryPlan).selectQueryPlan(this.$route.query.queryPlanName as string);
      return;
    }
    this.loadData();
  }

  /**
   * 执行查询方案
   * @param json
   */
  public queryList(json: string): void {
    this.paging.currentPage = 1;
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, JSON.parse(json));
    this.loadData();
  }

  private clearSelection(): void {
    (this.$refs.todoTable as OsTable).clearSelection();
  }

  private loadData(): void {
    this.tableOption.loading = true;
    todoService
      .getList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private getCustomers(): void {
    customerService
      .getAllUsingCustomer()
      .then(res => {
        const customerQuery = this.queryItemsOption.find(x => x.field === 'customerName');
        customerQuery!.optionData = res.map(x => {
          return { label: x.companyName, value: x.companyName };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  @Watch('queryForm.createTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }
}
